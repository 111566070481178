<div class="layout-topbar">
  <button
    #menubutton
    (click)="onMenuToggle()"
    class="p-link layout-menu-button layout-topbar-button m-0"
    data-automationId="sidebar_toggle"
    aria-label="Toggle Sidebar"
  >
    <div
      class="desktop-icon wi-icon"
      [class.wi-panel-close]="!layoutState.staticMenuDesktopInactive"
      [class.wi-panel-open]="layoutState.staticMenuDesktopInactive"
    ></div>
    <div class="mobile-icon wi-icon wi-menu"></div>
  </button>

  <app-breadcrumbs></app-breadcrumbs>

  <div
    #topbarmenu
    (click)="profileOverlay.toggle($event)"
    *ngIf="!!authenticationService.userInitials"
    class="layout-topbar-avatar"
  >
    <p-avatar
      [label]="userInitials"
      [pTooltip]="userName"
      shape="circle"
      size="large"
      tooltipPosition="left"
      data-automationId="user_menu_button"
    ></p-avatar>

    <i
      class="pi ms-2"
      [class.pi-angle-down]="!profileOverlay.overlayVisible"
      [class.pi-angle-up]="profileOverlay.overlayVisible"
    ></i>
  </div>
</div>

<p-overlayPanel #profileOverlay styleClass="topbar-profile-overlay">
  <ng-template pTemplate>
    <div class="d-flex flex-column align-content-between">
      <button
        (click)="toggleTheme()"
        class="position-absolute top-0 end-0 p-button-text m-2"
        title="Change Theme"
        icon="pi {{
          layoutService.config.theme === 'light' ? 'pi-moon' : 'pi-sun'
        }}"
        pButton
        type="button"
      ></button>

      <div class="d-flex flex-column align-items-center p-3 pb-0">
        <p-avatar
          [label]="userInitials"
          size="large"
          shape="circle"
          class="mt-3"
        ></p-avatar>

        <h4 class="mt-3 mb-3" [innerHTML]="userName"></h4>

        <ng-container *ngIf="!isPersonalTenant">
          <p class="mb-0">{{ districtName }}</p>
          <div *ngFor="let name of schoolNames">
            <p class="mb-0">{{ name }}</p>
          </div>
        </ng-container>

        <p
          *ngFor="let role of userRoles; trackBy: getTracking"
          class="p-text-secondary text-center m-0"
        >
          {{ role }}
        </p>
      </div>

      <app-select-tenant class="p-3 pt-0"></app-select-tenant>

      <div
        class="d-flex flex-column align-content-stretch"
        *ngIf="{ context: userContext$ | async } as user"
      >
        <a
          href="https://form.asana.com/?k=R4DkVkE7--fjmj78Y-AooQ&d=1157280955375439"
          target="_blank"
          class="p-button p-button-text text-start"
          >Provide Feedback</a
        >

        <button
          (click)="signOut()"
          class="p-button p-button-text text-start"
          label="Log Out"
          type="button"
          pButton
        ></button>

        <button
          (click)="loadMockData()"
          *ngIf="mockDataButtonVisibility"
          class="p-button p-button-warning text-start mt-3"
          [style]="{ 'border-radius': '3px' }"
          icon="pi pi-database"
          iconPos="right"
          label="Load Mock Data"
          type="button"
          pButton
          data-automationId="load_mock_data_button"
        ></button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
