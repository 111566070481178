import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@wilson/wilsonng';
import { Wilson } from 'src/def/Wilson';
import UserTenant = Wilson.UserTenant;
import { TenantService } from 'src/app/services/tenant.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select-tenant',
  templateUrl: './select-tenant.component.html',
})
export class SelectTenantComponent implements OnInit {
  availableTenants: UserTenant[] = [];
  hasMultipleTenants: boolean;
  selectedTenant: UserTenant;
  tenantName: string;

  constructor(
    private authenticationService: AuthenticationService,
    private tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.initDropdown();
  }

  initDropdown(): void {
    this.tenantName = this.authenticationService.currentUser['districtName'];
    this.availableTenants = this.authenticationService.currentUser['tenants'];
    this.hasMultipleTenants = this.availableTenants.length > 1;
  }

  onSelectedTenantChange(): void {
    this.updateTenant(this.selectedTenant).subscribe(() => {
      this.getWindow().location.assign('/');
    });
  }

  getWindow(): Window {
    return window;
  }

  updateTenant(tenant: UserTenant): Observable<void> {
    return this.tenantService.updateTenant(tenant);
  }
}
