<h1>FHP Admin Dashboard</h1>

<app-fun-state
  [(state)]="state"
  [showLevelFilter]="true"
  [showWeekFilter]="false"
  [isWeekMultiSelect]="true"
  [isClassMultiSelect]="true"
  [unitSortOrder]="3"
  (stateChange)="getReports()"
></app-fun-state>

<ng-container *ngIf="!state?.isEmpty">
  <ng-container *ngIf="report?.length">
    <div
      *ngFor="let weekReport of report; let i = index"
      class="week-report-container level-{{ state.class.level }}"
    >
      <div class="d-flex justify-content-between">
        <h4 class="fw-bold">{{ weekReport.title }}</h4>
        <p>
          Level: {{ state.class.level }}, Unit: {{ state.unit }}, Class:
          {{ state.extras['all'] ? 'All' : state.class.name }}
        </p>
      </div>
      <div class="d-flex">
        <div class="card card-lg admin">
          <h5 class="fw-bold text-center">
            FHP Performance on Completed Activities
          </h5>
          <p-chart
            title="Click to see data"
            [data]="weekReport"
            [options]="chartOptions[i]"
            [plugins]="chartPlugins"
            (onDataSelect)="selectChartArea($event, i)"
            type="doughnut"
            class="clickable-chart"
          ></p-chart>
          <div class="content">
            <small
              class="fw-bold mt-4 mb-2 mx-3"
              [ngSwitch]="selectedChartAreas[i]"
            >
              <span *ngSwitchCase="0">
                {{ state.extras['all'] ? 'Classes' : 'Students' }} in green
              </span>
              <span *ngSwitchCase="1">
                {{ state.extras['all'] ? 'Classes' : 'Students' }} in yellow
              </span>
              <span *ngSwitchCase="2">
                {{ state.extras['all'] ? 'Classes' : 'Students' }} in red
              </span>
              <span *ngSwitchCase="4" class="text-secondary fw-normal">
                Select a section on the chart to view results.
              </span>
              <span *ngSwitchDefault class="text-secondary fw-normal">
                No Results
              </span>
            </small>
            <ul
              class="student-list list-unstyled mx-3 mb-0"
              [ngSwitch]="selectedChartAreas[i]"
            >
              <ng-container *ngSwitchCase="0">
                <div *ngFor="let entity of weekReport.aboveBench | keyvalue">
                  <button
                    type="button"
                    class="btn btn-link p-0"
                    style="text-decoration: none"
                    [routerLink]="
                      weekReport.isStudent
                        ? ['/fhp', 'students', 'report', entity.value]
                        : [
                            '/fhp',
                            'students',
                            { classId: entity.value, unit: state.unit }
                          ]
                    "
                  >
                    {{ entity.key }}
                  </button>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="1">
                <div *ngFor="let entity of weekReport.atRisk | keyvalue">
                  <button
                    type="button"
                    class="btn btn-link p-0"
                    style="text-decoration: none"
                    [routerLink]="
                      weekReport.isStudent
                        ? ['/fhp', 'students', 'report', entity.value]
                        : [
                            '/fhp',
                            'students',
                            { classId: entity.value, unit: state.unit }
                          ]
                    "
                  >
                    {{ entity.key }}
                  </button>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <div *ngFor="let entity of weekReport.belowBench | keyvalue">
                  <button
                    type="button"
                    class="btn btn-link p-0"
                    style="text-decoration: none"
                    [routerLink]="
                      weekReport.isStudent
                        ? ['/fhp', 'students', 'report', entity.value]
                        : [
                            '/fhp',
                            'students',
                            { classId: entity.value, unit: state.unit }
                          ]
                    "
                  >
                    {{ entity.key }}
                  </button>
                </div>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div
            class="card card-sm admin d-flex flex-column text-center align-items-center justify-content-center"
          >
            <img
              src="./assets/img/icons/students-icon.svg"
              alt="students icon"
              class="card-icon"
            />
            <div class="stat-lg">
              {{ statsData[i]?.studentsCompletedActivities }}
            </div>
            <p>Students Completed<br />One or More Activities</p>
          </div>
          <div
            class="card card-sm admin d-flex flex-column text-center align-items-center justify-content-center"
          >
            <img
              src="./assets/img/icons/activities-completed-icon.svg"
              alt="activities completed icon"
              class="card-icon"
            />
            <div class="stat-lg">
              {{ statsData[i]?.totalActivitiesCompleted }}
            </div>
            <p>Activities<br />Completed</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="report?.length === 0">
    <div class="p-5 mt-5 text-center">
      <p>
        It looks like there are no completed FUN HUB Practice activities yet for
        this class.<br />Check back later when the students have completed their
        activities assigned.
      </p>
    </div>
  </ng-container>
</ng-container>

<app-empty-roster *ngIf="state?.isEmpty"></app-empty-roster>
