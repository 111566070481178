import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { ApiService, SpinnerModule, TitleService } from '@wilson/wilsonng';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { InitialLoadService } from './services/initial-load.service';
import { UIModule } from './ui.module';
import { RouterModule, TitleStrategy } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabMenuModule } from 'primeng/tabmenu';
import { DatePipe } from '@angular/common';

import { SettingsManagementComponent } from './pages/management/settings/settings.component';
import { UsersManagementComponent } from './pages/management/users/users.component';
import { DistrictReportsComponent } from './pages/reports/district-reports/district-reports.component';
import { SchoolReportsComponent } from './pages/reports/school-reports/school-reports.component';
import { MyClassesComponent } from './pages/roster/my-classes/my-classes.component';
import { MyStudentsComponent } from './pages/roster/my-students/my-students.component';
import { ClassOverviewComponent } from './pages/roster/class-overview/class-overview.component';
import { NewClassComponent } from './pages/roster/new-class/new-class.component';
import { StudentReportComponent } from './pages/reports/student-report/student-report.component';
import { UnitReportComponent } from './pages/reports/unit-report/unit-report.component';
import { PrintStudentReportComponent } from './pages/reports/student-report/print-student-report.component';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { NewTestComponent } from './pages/test/new-test/new-test.component';
import { EditTestComponent } from './pages/test/edit-test/edit-test.component';
import { TestComponent } from './pages/test/test.component';
import { TranslocoRootModule } from './transloco-root.module';
import { PageTitleStrategy } from './services/page-title.service';
import { EmptyRosterComponent } from './pages/roster/empty-roster/empty-roster.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthCheckInterceptor } from './interceptors/auth-check.interceptor';
import { TenantInterceptor } from './interceptors/tenant-interceptor';
import { ClassComponent } from './pages/fun-hub-practice/class/class.component';
import { PasswordPickerComponent } from './components/password-picker/password-picker.component';
import { DateTransformPipe } from './pipes/date-transform.pipe';
import { AssignmentStudentsComponent } from './pages/fun-hub-practice/assignment/components/assignment-students/assignment-students.component';
import { CreateAssignmentComponent } from './pages/fun-hub-practice/assignment/create-assignment/create-assignment.component';
import { EditAssignmentComponent } from './pages/fun-hub-practice/assignment/edit-assignment/edit-assignment.component';
import { AssignmentTransformPipe } from './pipes/assignment-transform.pipe';
import { ReportsAudioComponent } from './pages/fun-hub-practice/reports-audio/reports-audio.component';
import { AssignmentsComponent } from './pages/fun-hub-practice/assignment-results/assignments/assignments.component';
import { ActiveAssignmentsComponent } from './pages/fun-hub-practice/assignment-results/components/active-assignments/active-assignments.component';
import { ClosedAssignmentsComponent } from './pages/fun-hub-practice/assignment-results/components/closed-assignments/closed-assignments.component';
import { AssignmentResultsComponent } from './pages/fun-hub-practice/assignment-results/results/assignment-results.component';
import { StudentResultsComponent } from './pages/fun-hub-practice/student-results/results/student-results.component';
import { IndividualStudentReportComponent } from './pages/fun-hub-practice/student-results/report/ind-student-report.component';
import { AvatarStudentComponent } from './components/avatar-student/avatar-student.component';
import { FunStateComponent } from './components/fun-state/fun-state.component';
import { AssignmentRegistriesComponent } from './pages/fun-hub-practice/assignment/components/assignment-registries/assignment-registries.component';
import { PreviewAssignmentComponent } from './pages/fun-hub-practice/assignment/preview-assignment/preview-assignment.component';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminDashboardComponent,
    DashboardComponent,
    SettingsManagementComponent,
    UsersManagementComponent,
    DistrictReportsComponent,
    SchoolReportsComponent,
    MyClassesComponent,
    MyStudentsComponent,
    EmptyRosterComponent,
    ClassOverviewComponent,
    NewClassComponent,
    NewTestComponent,
    EditTestComponent,
    TestComponent,
    StudentReportComponent,
    UnitReportComponent,
    PrintStudentReportComponent,
    AssignmentsComponent,
    ActiveAssignmentsComponent,
    ClosedAssignmentsComponent,
    AssignmentResultsComponent,
    StudentResultsComponent,
    IndividualStudentReportComponent,
    ReportsAudioComponent,
    ClassComponent,
    PasswordPickerComponent,
    AssignmentStudentsComponent,
    AssignmentRegistriesComponent,
    CreateAssignmentComponent,
    EditAssignmentComponent,
    DateTransformPipe,
    AssignmentTransformPipe,
    AvatarStudentComponent,
    FunStateComponent,
    PreviewAssignmentComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    ToastModule,
    RouterModule.forRoot([], {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'disabled',
      paramsInheritanceStrategy: 'always',
    }),
    AppRoutingModule,
    UIModule,
    TranslocoRootModule,
    OverlayPanelModule,
    TabMenuModule,
  ],
  providers: [
    MessageService,
    ConfirmationService,
    DatePipe,
    ApiService,
    TitleService,
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    {
      provide: 'productName',
      useValue: 'Fun360',
    },
    {
      provide: 'baseApiUrl',
      useValue: document.getElementsByTagName('base')[0].href,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthCheckInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (ils: InitialLoadService) => () => ils.getInitialLoad(),
      deps: [InitialLoadService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
