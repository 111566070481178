import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, SpinnerService } from '@wilson/wilsonng';
import { MenuItem } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';
import { LayoutService, LayoutState } from '../app.layout.service';
import { Observable, tap } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import User = Wilson.User;
import { OverlayPanel } from 'primeng/overlaypanel';
import { InitialLoadService } from '../../services/initial-load.service';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss'],
})
export class AppTopBarComponent implements OnInit {
  userInitials: string;
  userRoles: string[] = [];
  userName: string;
  items!: MenuItem[];
  layoutState: LayoutState;
  mockDataButtonVisibility: boolean;
  userContext$: Observable<User>;
  districtName: string;
  schoolNames: string[];
  isDistrictAdminOnly: boolean;
  isPersonalTenant: boolean;

  @ViewChild('menubutton') menuButton!: ElementRef;
  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;
  @ViewChild('topbarmenu') menu!: ElementRef;
  @ViewChild('profileOverlay') profileOverlay!: OverlayPanel;

  constructor(
    public layoutService: LayoutService,
    public authenticationService: AuthenticationService,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private adminService: AdminService,
    private initialLoadService: InitialLoadService
  ) {}

  ngOnInit(): void {
    this.layoutState = this.layoutService.state;
    this.setupUser();
    this.setupMockDataButton();
  }

  setupUser(): void {
    const currentUser = this.authenticationService.currentUser;
    this.userContext$ = this.userService.userContext$;
    this.userInitials = this.authenticationService.userInitials;
    this.userName = currentUser.name;
    this.districtName = currentUser['districtName'];
    this.schoolNames = currentUser['schoolNames'];
    this.isPersonalTenant = currentUser['isPersonalTenant'];

    if (this.userService.isDistrictAdmin())
      this.userRoles = [...this.userRoles, 'District Admin'];

    if (this.userService.isSchoolAdmin())
      this.userRoles = [...this.userRoles, 'School Admin'];

    if (this.userService.isTeacher())
      this.userRoles = [...this.userRoles, 'Teacher'];

    this.isDistrictAdminOnly =
      this.userRoles.includes(`District Admin`) && this.userRoles.length === 1;
  }

  setupMockDataButton(): void {
    // we only show this button if we're not on production and
    // the user doesn't already have all 3 roles
    this.mockDataButtonVisibility =
      !this.initialLoadService.initialLoad.isProduction &&
      this.userService.user.isPersonalTenant &&
      this.userRoles.length < 3;
  }

  onMenuToggle(): void {
    this.layoutService.onMenuToggle();
  }

  toggleTheme(): void {
    this.layoutService.onChangeTheme(
      this.layoutService.config.theme === 'light' ? 'dark' : 'light'
    );
  }

  showProfileSidebar(): void {
    this.layoutService.showProfileSidebar();
  }

  signOut(): void {
    this.authenticationService.logout();
  }

  getTracking(index): number {
    return index;
  }

  loadMockData(): void {
    this.profileOverlay.toggle(false);
    this.spinnerService.show();
    this.adminService.loadMockData().subscribe(() => this.signOut());
  }
}
