import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthenticationService } from '@wilson/wilsonng';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class FhpAuthGuard {
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | RedirectCommand {
    return this.verifyUserAccess(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | RedirectCommand {
    return this.verifyUserAccess(route, state);
  }

  private verifyUserAccess(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | RedirectCommand {
    const userIsLoggedIn = this.authenticationService.isLoggedIn();
    if (!userIsLoggedIn || state.url !== '/') return userIsLoggedIn;

    if (this.userService.userHasUttAccess() && !this.userService.userHasFhpAccess()) {
      // If the user is trying to access the "root" route, and does not have FhpAccess,
      // but does have UttAccess, send them to the /classes page or /school-reports page
      return this.userService.isTeacher()
        ? new RedirectCommand(this.router.parseUrl('/utt/classes'))
        : new RedirectCommand(this.router.parseUrl('/utt/school-reports'));
    } else if (
      // User is a School Admin (AND not a teacher) with FHP access, and is trying to access the root route
      this.userService.isSchoolAdmin() &&
      !this.userService.isTeacher() &&
      this.userService.userHasFhpAccess()
    ) {
      return new RedirectCommand(this.router.parseUrl('/admin'));
    }

    return userIsLoggedIn;
  }
}
