<div class="d-flex flex-column h-100" *ngIf="isMenuVisible">
  <a class="layout-topbar-logo pt-3 pb-5 shadow-none" routerLink="">
    <img
      alt="logo"
      src="./assets/img/fundations-full{{
        theme !== 'light' ? '-white' : '-black'
      }}.svg"
    />
  </a>

  <app-menu class="flex-grow-1"></app-menu>
</div>
