import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private apiService: ApiService) {}

  loadMockData(): Observable<void> {
    return this.apiService.get<void>('admin/ModifyUserAllRoles/');
  }
}
